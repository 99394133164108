<template>
  <div id="dashboard">
    <Header
      :user="loggedUser"
      :currentRouteName="$route.name"
    />
    <b-container fluid="sm" class="dashboard-container">
      <b-row>
        <b-col>
          <profile-card
            :user="loggedUser"
            :extendedUser="loggedUser.getBusiness"
            v-bind:editable="false"
          />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Header from '@/components/Header/Header.vue'
import ProfileCard from '../components/Profile/ProfileCard'

export default {
  name: 'Dashboard',
  components: {
    ProfileCard,
    Header
  },
  computed: {
    loggedUser () {
      return this.$store.getters.loggedUser
    }
  }
}
</script>

<style lang="less">
  @import 'dashboard.less';
</style>
